let caseShows = [
  {
    title: `从0到1，见证品牌腾飞`,
    top: [
      "2015年，旗舰店开店不到2年，GMV已破亿",
      "2020年旗舰店全年销售额破5亿，同比增速高达73%",
      "2022年618活动期间，旗舰店GMV成交破亿，同比21年增速30%+，行业排名TOP.1注解：2022年618活动期间（2022.5.31-2022.6.20）淘宝平台，奶粉/辅食/营养品/零食类目下店铺榜单TOP1"
    ],
    body: `美素佳儿，荷兰皇家菲仕兰旗下的婴幼儿奶粉品牌，迄今已有140多年的历史，在全球享有较高声誉。荷兰皇家菲仕兰公司被荷兰皇室授予“皇家”称号，行业仅此一家。`,
    bodys: '在美素佳儿项目中，网营科技作为强有力的中轴，推动平台、品牌、网营的三方联动。从人群，体验，拉新，共振，四个方向合围发力。通过数字化运营体系建设，人群资产的提升与流转，以及会员规模与活跃度提升，深化消费者体系的运营。通过提供360°客服体系的极致服务，场景化交互视觉体验满足消费体验的极致追求。通过全链路布局下的拉新促活，平台工具深度运营，联动共创，促进多场景持续拉新。通过内容渠道策略升级，私域互动促活，高频直播收割，实现公私域内容共振。'
    ,
    img1: "https://prod.oss.netopstec.com/net-caseShow-101.jpg",
    img2: "https://prod.oss.netopstec.com/net-caseShow-111.jpg",
    img3: "https://prod.oss.netopstec.com/net-caseShow-121.jpg	",
  },
  {
    title: `高效敏捷，跨越式增长`,
    top: [
      "2020年，入选“天猫新锐品牌”，斩获“天猫最佳黑马新人奖”",
      "店铺首次参与前N，前一小时破百万，前一小时药品行业top1",
      "全阶段行业TOP2"
    ],
    body: '“大宠爱”作为硕腾旗下广受宠物主人信赖的宠物驱虫品牌，于1990年上市，是首个FDA批准的经局部用药预防全身心丝虫和驱跳蚤、防治耳螨的产品。大宠爱内外同驱，对家人对犬猫都非常安全，猫咪舔毛也无忧。',
    bodys: '在大宠爱项目中，网营科技通过线上多点营销布局推动裂变传播，用趣味话题媒介组合实现传播赋能。联合站外热点综艺，进行站内二次曝光宣传。为店铺连续打造直播间特色活动，策划主题引爆直播间，提高品牌转化，使品牌破圈成长。',
    img1: "https://prod.oss.netopstec.com/net-caseShows-head1.jpg",
    img2: "https://prod.oss.netopstec.com/net-caseShows-dca11.jpg",
    img3: "https://prod.oss.netopstec.com/net-caseShows-dca21.jpg	", 
  },
  {
    title: `玩转流量 助力品牌霸榜`,
    top: [
      "2021年上半年，全渠道GMV同比增长56%，远超行业均速",
      "2021年2月，参加天猫情人节活动，天猫旗舰店全店GMV排名天猫巧克力行业TOP1，JD POP旗舰店全店GMV排名JD POP巧克力行业TOP1",
      "2021年618活动期间，同比去年618增幅116%"
    ],
    body: `GODIVA，1926年享誉全球的比利时皇室御用巧克力品牌。GODIVA歌帝梵由巧克力大师Pierre Draps于布鲁塞尔创立。历经近一个世纪的发展，始终秉承比利时精湛巧克力制作工艺，甄选优质原料，确保每一颗巧克力产品的上乘品质，足迹已遍布全球100多个国家和地区。`,
    bodys: '在歌帝梵项目中，网营科技通过自身强大的资源整合优势，结合品类特性，与超头部主播形成战略合作。在供应链端，则更加强化备货及销售的确定性，以“强大流量+稳定供应链”，促进品牌快速成长。会员新人数同比提升240% ，会员渗透为行业TOP。在巧克力行业品牌淡季，也能保持增幅74%的高增长态势。'
    ,
    img1: "https://prod.oss.netopstec.com/net-caseShow-1.png",
    img2: "https://prod.oss.netopstec.com/net-caseShow-2.png",
    img3: "https://prod.oss.netopstec.com/net-caseShow-3.png	",
  },
  {
    title: `从0到1 燃爆新消费群体`,
    top: [
      "精细化运营调优，日常GMV环比提高67%+",
      "重新搭建、优化会员体系，6月底会员入会数提升39%",
      "淘宝直播头部达人矩阵式等，单场成交20万+"
    ],
    body: `ffit8成立于2019年10月，品牌定位蛋白质公司，以解决“未来的年轻人吃什么”为目标，专注打造蛋白质类健康食品。产品围绕“潮、酷、燃”的品牌调性和风格。2020年10月，获天猫美食“十大新锐品牌”；2020年10月，获天然健康食品行业大会‘HEY有料’30强。`,
    bodys: '在ffit8项目中，网营科技通过对产品人群的精准定位，洞察新消费群体的内容与渠道，量身打造从曝光、触达、种草到销售再到复购的营销闭环，同时玩转跨界营销，用好玩、有趣、创意的营销玩法，来承接“有趣又要有料”的新消费群体的购买心态，助力这一新兴品牌实现线上销量的阶段式成长。',
    img1: "https://prod.oss.netopstec.com/net-caseShow-4.png",
    img2: "https://prod.oss.netopstec.com/net-caseShow-51.jpg",
    img3: "https://prod.oss.netopstec.com/net-caseShow-6.png	",
  },
  {
    title: `从0到1 线上销量飞跃`,
    top: [
      "2018年10月，半年累计GMV超1000W，获得京东飞跃品牌奖",
      "2019年GMV1.3亿+，实现260%增长；进入美力联盟，成为首批15家美妆战略合作JDP",
      "2020年双11期间，GMV2800万+，同比增长41%",
      "2021年618期间，销量领跑底妆行业"
    ],
    body: `韩国TOP3化妆品集团爱敬旗下品牌。Age 20’s 寓意着让肌肤重返20岁，品牌有5种锁水成分予以肌肤滋润，采用创新技术，将65%的水分精华和粉底融合，打造自然无暇的水润肌肤。`,
    bodys: '在爱敬项目中，网营科技从货品运营，资源整合，精细化运营三个方向入手。爆款卡位，获取品类核心搜索流量。配合品牌货品策略，完成新老款过渡，维持店铺良性销售。紧跟平台战略发展方向，寻找合作机会。选择高价值核心入口，碎片化流量整合。与采销维持良好的关系，提高日常业务效率。一套“组合拳”，助力品牌快速飞跃，快速抢占底妆行业销量TOP1。'
    ,
    img1: "https://prod.oss.netopstec.com/net-caseShow-72.png",
    img2: "https://prod.oss.netopstec.com/net-caseShow-82.png",
    img3: "https://prod.oss.netopstec.com/net-caseShow-92.png",
  },
  {
    title: `资源整合 品牌强力助跑`,
    top: [
      "2017年开店至今，每年GMV均呈200%+增长",
      "2020年双11同比增长238%，女装排名较去年提升499名",
      "2021年618GMV同比增长197%，32分钟超去年616全天",
      "2021年618期间5款专供连衣裙皆为TOP10，成交占全店GMV30%"
    ],
    body: `bebe来自美国，品牌创立于1976年，她唤醒一种新的生活理念-无关于年龄，而是一种态度。坚持采用真正的原创设计，诠释每一季的潮流时尚定义，专为有时尚感知且自信的女性设计，作为一个全球品牌，具象了感性的、精致的生活方式。40年来，bebe已确立了其在世界时装零售品牌行业中重要的地位。`,
    bodys:'在bebe项目中，网营从明星资源布局，货品重构及O2O，营销内容加持三方面布局。结合品牌方明星代言资源，快速布局渠道资源，收割明星流量红利，促进销售爆发。提升商品同款率，优化库存结构，提升库存周转效率。多渠道内容投放，全方位提升品牌曝光，抢占公域流量，为品牌持续输出影响力。'
    ,
    img1: "https://prod.oss.netopstec.com/net-caseShow-131.png",
    img2: "https://prod.oss.netopstec.com/net-caseShow-14.png",
    img3: "https://prod.oss.netopstec.com/net-caseShow-15.png",
  },
]

export { caseShows };